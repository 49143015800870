import React from 'react'
import PropTypes from 'prop-types'
import filterIcon from '../../../../static/filter.svg'
import { StyledButton, StyledSticky } from './styledComponents'

const FilterButton = ({ containerClassName, onClick }) => {
  return (
    <StyledSticky
      mode='bottom'
      boundaryElement={`.${containerClassName}`}
      positionRecheckInterval={10}
    >
      <StyledButton type='button' onClick={onClick}>
        <img src={filterIcon} alt='filter' />
      </StyledButton>
    </StyledSticky>
  )
}

FilterButton.propTypes = {
  containerClassName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default FilterButton
