import React from 'react'
import { Modal, ModalHeader } from '../../modal'
import { StyledFilter, StyledModalBody } from './styledComponents'

const FilterModal = ({ open, onClose, title, ...rest }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalBody>
        <ModalHeader onClose={onClose}>{title}</ModalHeader>
        <StyledFilter {...rest} />
      </StyledModalBody>
    </Modal>
  )
}

export default FilterModal
