import React, { memo, useEffect, useState } from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import PropTypes from 'prop-types'
import {
  StyledCheckbox,
  StyledForm,
  StyledButton,
  StyledResetButton,
} from './styledComponents'
import declOfNum from '../../../utils/declOfNum'
import numberWithSpaces from '../../../utils/numberWithSpaces'

const Filter = ({
  total,
  priceRange,
  onPriceChange,
  squareRange,
  onSquareChange,
  onFilterApplyClick,
  onFilterClear,
  types,
  onTypesChange,
  floors,
  onFloorsChange,
  ...rest
}) => {
  const [price, setPrice] = useState(priceRange)
  const [square, setSquare] = useState(squareRange)
  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedFloors, setSelectedFloors] = useState([])

  const handleTypeChange = id => {
    setSelectedTypes(values => {
      if (values.includes(id)) {
        return values.filter(t => t !== id)
      }
      return [...values, id]
    })
  }

  const handleFloorChange = id => {
    setSelectedFloors(values => {
      if (values.includes(id)) {
        return values.filter(t => t !== id)
      }
      return [...values, id]
    })
  }

  const isFilterChanged =
    price.min !== priceRange.min ||
    price.max !== priceRange.max ||
    square.min !== squareRange.min ||
    square.max !== squareRange.max ||
    !!selectedTypes.length ||
    !!selectedFloors.length

  const handleFilterClear = () => {
    setPrice(priceRange)
    setSquare(squareRange)
    setSelectedTypes([])
    setSelectedFloors([])
    onFilterClear()
  }

  useEffect(() => {
    if (onTypesChange) {
      onTypesChange(selectedTypes)
    }
  }, [onTypesChange, selectedTypes])

  useEffect(() => {
    if (onFloorsChange) {
      onFloorsChange(selectedFloors)
    }
  }, [onFloorsChange, selectedFloors])

  useEffect(() => {
    if (!isFilterChanged) {
      onFilterClear()
    }
  }, [isFilterChanged])

  return (
    <StyledForm {...rest}>
      <fieldset className='fieldset'>
        <h3 className='title'>Стоимость</h3>
        <InputRange
          minValue={priceRange.min}
          maxValue={priceRange.max}
          formatLabel={() => ''}
          value={price}
          onChange={setPrice}
          onChangeComplete={onPriceChange}
        />
        <div className='slider-labels'>
          <label>От {numberWithSpaces(price.min)} ₽</label>
          <label>До {numberWithSpaces(price.max)} ₽</label>
        </div>
      </fieldset>

      <fieldset className='fieldset'>
        <h3 className='title'>Площадь</h3>
        <InputRange
          minValue={squareRange.min}
          maxValue={squareRange.max}
          formatLabel={() => ''}
          value={square}
          onChange={setSquare}
          onChangeComplete={onSquareChange}
        />
        <div className='slider-labels'>
          <label>
            От {numberWithSpaces(square.min)} м<sup>2</sup>
          </label>
          <label>
            До {numberWithSpaces(square.max)} м<sup>2</sup>
          </label>
        </div>
      </fieldset>

      <fieldset className='fieldset'>
        <h3 className='title'>Тип постройки</h3>
        {types.map(({ name, id }) => (
          <StyledCheckbox
            key={id}
            checked={selectedTypes.includes(id)}
            onChange={() => handleTypeChange(id)}
          >
            {name}
          </StyledCheckbox>
        ))}
      </fieldset>

      <fieldset className='fieldset'>
        <h3 className='title'>Этажность</h3>
        {floors.map(({ name, id }) => (
          <StyledCheckbox
            key={id}
            checked={selectedFloors.includes(id)}
            onChange={() => handleFloorChange(id)}
          >
            {name}
          </StyledCheckbox>
        ))}
      </fieldset>

      <StyledButton type='button' onClick={onFilterApplyClick}>
        Показать{' '}
        {isFilterChanged &&
          `${total} ${declOfNum(total, ['проект', 'проекта', 'проектов'])}`}
      </StyledButton>

      {isFilterChanged && (
        <StyledResetButton type='button' onClick={handleFilterClear}>
          Очистить фильтр
        </StyledResetButton>
      )}
    </StyledForm>
  )
}

Filter.propTypes = {
  priceRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  squareRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  types: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  floors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onSquareChange: PropTypes.func.isRequired,
  onTypesChange: PropTypes.func.isRequired,
  onFilterApplyClick: PropTypes.func.isRequired,
  onFilterClear: PropTypes.func.isRequired,
}

Filter.defaultProps = {
  priceRange: { min: 0, max: 0 },
}

export default memo(Filter)
