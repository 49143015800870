import styled from 'styled-components'
import { spacing, device } from '../../utils/mixins'
import Button from '../button'
import Container from '../container'
import { asideWidth, asideWidthDesktop } from './aside/styledComponents'

export const StyledPageTitle = styled.h1`
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  margin: 20px auto;

  @media ${device.tablet} {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 580px;
  }

  @media ${device.tabletLand}, ${device.desktop} {
    font-size: 36px;
    line-height: 50px;
    max-width: 1000px;
  }
`

export const StyledContainer = styled(Container)`
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: ${spacing(4)};
`

export const StyledProductsContainer = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;

  @media ${device.tabletLand} {
    flex: 0 0 calc(100% - ${asideWidth});
    max-width: calc(100% - ${asideWidth});
  }

  @media ${device.desktop} {
    flex: 0 0 calc(100% - ${asideWidthDesktop});
    max-width: calc(100% - ${asideWidthDesktop});
  }
`

export const StyledProductsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 100%;

  @media ${device.tablet} {
    flex: 0 0 calc(100% + 20px);
    max-width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }

  @media ${device.tabletLand} {
    flex: 0 0 calc(100% + 30px);
    max-width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }

  @media ${device.desktop} {
    flex: 0 0 calc(100% + 24px);
    max-width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: ${spacing(4)};
`

export const StyledLoadMoreButton = styled(Button)`
  max-width: 240px;
`
