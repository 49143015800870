import React from 'react'
import PropTypes from 'prop-types'
import { StyledContentBlock } from './styledComponents'

const ContentBlock = ({ title, listTitle, list, image }) => {
  return (
    <StyledContentBlock>
      <div className='wrapper'>
        <h2 className='title'>
          <img className='image' src={image.mediaFile.publicURL} alt={title} />
          <span>{title}</span>
        </h2>

        <h3 className='list-title'>{listTitle}</h3>

        <ol className='list'>
          {list.map(({ id, title, text }, index) => (
            <li className='list-item' key={id}>
              <h3 className='list-item-title'>
                <span className='index'>{index + 1}</span>
                {title}
              </h3>
              <p className='list-item-text'>{text}</p>
            </li>
          ))}
        </ol>
      </div>
    </StyledContentBlock>
  )
}

ContentBlock.propTypes = {
  title: PropTypes.string,
  listTitle: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  image: PropTypes.object,
}

export default ContentBlock
