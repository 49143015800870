/**
 * @param {number} number
 * @param {[string]} words
 * @example
 * declOfNum(1, ['минута', 'минуты', 'минут']); // вернёт — минута
 * declOfNum(2, ['минута', 'минуты', 'минут']); // вернёт — минуты
 * declOfNum(5, ['минута', 'минуты', 'минут']); // вернёт — минут
 */
const declOfNum = (number, words) => {
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ]
}

export default declOfNum
