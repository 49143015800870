import styled from 'styled-components'
import { spacing, device } from '../../../utils/mixins'

export const StyledContentBlock = styled.section`
  @media ${device.tabletLand} {
    border-top: 2px solid #e0e0e0;
    padding-top: ${spacing(4)};
    margin-top: ${spacing(2)};
  }

  @media ${device.desktop} {
    border-top: 2px solid #e0e0e0;
    padding-top: ${spacing(8)};
    margin-top: ${spacing(4)};
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 4px solid #e0e0e0;
    padding: ${spacing(1, 2)};

    @media ${device.tabletLand}, ${device.desktop} {
      padding-bottom: ${spacing(7)};
    }

    .title {
      border-bottom: 2px solid #e0e0e0;
      font: 400 14px/20px Tenor, sans-serif;
      padding: ${spacing(3, 0)};
      margin: 0;
      position: relative;

      @media ${device.tabletLand}, ${device.desktop} {
        font-size: 24px;
        line-height: 25px;
        padding: ${spacing(6)};
      }

      span {
        display: block;

        @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
          max-width: 80%;
        }
      }

      .image {
        position: absolute;
        top: calc(100% - (80px / 2));
        right: ${spacing(-4)};
        height: 80px;
        width: 80px;

        @media ${device.tablet} {
          top: 16px;
          right: 8px;
          height: 114px;
          width: 114px;
        }

        @media ${device.tabletLand}, ${device.desktop} {
          top: -16px;
          right: 0;
          height: 160px;
          width: 160px;
        }
      }
    }

    .list-title {
      border-bottom: 3px solid #9a6244;
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      margin: ${spacing(2, 0, 0)};
      padding: ${spacing(0, 0, 1 / 2)};

      @media ${device.tabletLand}, ${device.desktop} {
        margin-left: ${spacing(6)};
        margin-right: ${spacing(6)};
        margin-top: ${spacing(4)};
      }
    }

    .list {
      margin: 0;
      padding: ${spacing(2, 0, 0)};
      list-style-type: none;

      @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
        column-count: 2;
        column-gap: 100px;
        padding-top: ${spacing(3)};
      }

      @media ${device.tabletLand}, ${device.desktop} {
        margin-left: ${spacing(6)};
        margin-right: ${spacing(6)};
      }

      .list-item {
        margin-bottom: ${spacing(2)};
        break-inside: avoid;
        page-break-inside: avoid;

        @media ${device.tabletLand}, ${device.desktop} {
          margin-bottom: ${spacing(5)};
        }

        .list-item-title {
          display: flex;
          font: 400 14px/22px Tenor, sans-serif;
          margin: 0;

          @media ${device.tabletLand}, ${device.desktop} {
            font-size: 20px;
            line-height: 25px;
          }

          .index {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 11px;
            line-height: 15px;
            background-color: #9a6244;
            border-radius: 50%;
            color: #fff;
            margin-right: ${spacing(1)};
            height: 20px;
            min-height: 20px;
            width: 20px;
            min-width: 20px;
          }
        }

        .list-item-text {
          font-size: 12px;
          line-height: 17px;
          margin: ${spacing(1, 0, 0)};

          @media ${device.tabletLand}, ${device.desktop} {
            font-size: 14px;
            line-height: 20px;
            margin: ${spacing(2, 0, 3)};
          }
        }
      }
    }
  }
`
