import styled from 'styled-components'
import Sticky from 'react-sticky-el'
import { device, spacing } from '../../../utils/mixins'

export const StyledSticky = styled(Sticky)`
  position: absolute;
  bottom: 0;
  left: calc(100% - ${spacing(6)});
  z-index: 999;
`

export const StyledButton = styled.button`
  background-color: #9a6244;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 56px;
  width: 56px;
  z-index: 999;
  margin-bottom: 40px;

  &:focus {
    outline: none;
  }

  @media ${device.tabletLand}, ${device.desktop} {
    display: none;
  }
`
