import styled, { css } from 'styled-components'
import { spacing, device } from '../../../utils/mixins'
import { animated } from 'react-spring'

const clearButtonStyles = css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;

  @media ${device.tabletLand}, ${device.desktop} {
    font-size: 18px;
  }

  .list-button {
    ${clearButtonStyles}
    color: #0074b8;

    &:focus {
      outline: none;
    }

    svg {
      height: 8px;
      width: 14px;
      margin-left: ${spacing(1)};
    }
  }

  .direction-button {
    ${clearButtonStyles}
    margin-left: ${spacing(3)};
    height: 19px;
    width: 19px;
    svg {
      fill: #0074b8;
      height: 100%;
      width: 100%;
    }
  }
`

export const StyledPopup = styled(animated.div)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: ${spacing(1)};
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
  z-index: 100;

  ul {
    background-color: #fff;
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.3);
    padding: ${spacing(1)};
    list-style-type: none;
    margin: 0;
    text-align: right;

    li {
      cursor: pointer;
      line-height: 14px;
      padding: ${spacing(2, 0)};
      transition: opacity 300ms;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`
