import styled from 'styled-components'
import { device, spacing } from '../../../utils/mixins'
import { Checkbox } from '../../form-controls'
import Button from '../../button'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  .fieldset {
    border: none;
    padding: 0;
    margin: ${spacing(0, 0, 4)};

    .title {
      font-size: 14px;
      margin: ${spacing(0, 0, 1)};

      @media ${device.tabletLand}, ${device.desktop} {
        font-size: 18px;
      }
    }

    // INPUT RANGE
    .input-range {
      .input-range__slider {
        background-color: #fff;
        border: 1px solid #cccccc;
      }

      .input-range__track.input-range__track--active {
        background-color: rgba(0, 116, 184, 0.3);
      }
    }

    .slider-labels {
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: ${spacing(2)};
    }
  }
`

export const StyledCheckbox = styled(Checkbox)`
  margin-top: ${spacing(1)};
`

export const StyledButton = styled(Button)`
  font-weight: 400;
  font-size: 14px;
  max-height: 40px;
  margin-top: 0;
`

export const StyledResetButton = styled.button`
  font-size: 14px;
  background-color: transparent;
  border: none;
  color: #0074b8;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing(2)};
  text-decoration: underline;
  transition: all 300ms;

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
`
