import styled from 'styled-components'
import { device, spacing } from '../../../utils/mixins'

export const asideWidth = '280px'
export const asideWidthDesktop = '280px'
export const StyledAside = styled.aside`
  display: none;

  @media ${device.tabletLand}, ${device.desktop} {
    display: block;
    position: sticky;
    top: ${spacing(2)};
    flex: 0 0 100%;
    max-width: ${asideWidth};
    margin-top: ${spacing(3)};
    padding-left: 9px;
    padding-right: ${spacing(5)};

    @media ${device.desktop} {
      max-width: ${asideWidthDesktop};
    }
  }
`
