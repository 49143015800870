import { useEffect, useState } from 'react'

const filterByPrice = price => item =>
  item.minPrice >= price.min && (price.max === 0 || item.minPrice <= price.max)
const filterBySquare = square => item =>
  item.square >= square.min && (square.max === 0 || item.square <= square.max)
const filterByTypes = types => item =>
  !types.length || item.projectTypes?.some(({ id }) => types.includes(id))
const filterByFloors = floors => item =>
  !floors.length || floors.includes(item?.projectFloor?.id)

const useFilter = (products = [], priceRange, squareRange) => {
  const [price, setPrice] = useState(priceRange)
  const [square, setSquare] = useState(squareRange)
  const [types, setTypes] = useState([])
  const [floors, setFloors] = useState([])

  const [items, setItems] = useState([])

  useEffect(() => {
    const result = products
      .filter(filterByPrice.call(null, price))
      .filter(filterBySquare.call(null, square))
      .filter(filterByTypes.call(null, types))
      .filter(filterByFloors.call(null, floors))
    setItems(result)
  }, [price, square, types, floors, products])

  return { items, setPrice, setSquare, setTypes, setFloors }
}

export default useFilter
