import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledPopup } from './styledComponents'
import { useSpring } from 'react-spring'
import {
  SORT_VALUES,
  SORT_LABELS,
  SORT_DIRECTION,
} from '../../../pages/catalog'

const Sort = ({ onSortChange, onDirectionChange, sort, direction }) => {
  const [listOpen, setListOpen] = useState(false)
  const sortValues = Object.values(SORT_VALUES)

  const listProps = useSpring({
    opacity: listOpen ? 1 : 0,
  })

  const handleSortChange = key => {
    setListOpen(false)
    onSortChange(key)
  }

  const handleDirectionChange = () => {
    onDirectionChange(value =>
      Object.values(SORT_DIRECTION).find(d => d !== value),
    )
  }

  return (
    <StyledContainer>
      <span>Сортировать&nbsp;</span>

      <button
        className='list-button'
        type='button'
        onClick={() => setListOpen(v => !v)}
      >
        по {SORT_LABELS[sort]}
        <svg
          viewBox='0 0 14 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          style={{
            transform: listOpen ? 'rotate(180deg)' : 'rotate(0)',
          }}
        >
          <path d='M1 1L7.01493 7L13 1' stroke='#0074b8' />
        </svg>
      </button>

      {sort !== SORT_VALUES.DEFAULT && (
        <button
          className='direction-button'
          type='button'
          onClick={handleDirectionChange}
        >
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
            <path
              style={{ opacity: direction === SORT_DIRECTION.DESC ? 1 : '0.4' }}
              d='m496.1,138.3l-120.4-120.4c-7.9-7.9-20.6-7.9-28.5-7.10543e-15l-120.3,120.4c-7.9,7.9-7.9,20.6 0,28.5 7.9,7.9 20.6,7.9 28.5,0l85.7-85.7v352.8c0,11.3 9.1,20.4 20.4,20.4 11.3,0 20.4-9.1 20.4-20.4v-352.8l85.7,85.7c7.9,7.9 20.6,7.9 28.5,0 7.9-7.8 7.9-20.6 5.68434e-14-28.5z'
            />
            <path
              style={{ opacity: direction === SORT_DIRECTION.ASC ? 1 : '0.4' }}
              d='m287.1,347.2c-7.9-7.9-20.6-7.9-28.5,0l-85.7,85.7v-352.8c0-11.3-9.1-20.4-20.4-20.4-11.3,0-20.4,9.1-20.4,20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5,0-7.9,7.9-7.9,20.6 0,28.5l120.4,120.4c7.9,7.9 20.6,7.9 28.5,0l120.4-120.4c7.8-7.9 7.8-20.7-0.1-28.5l0,0z'
            />
          </svg>
        </button>
      )}

      <StyledPopup
        style={{
          ...listProps,
          visibility: listProps.opacity.interpolate(v =>
            v === 0 ? 'hidden' : 'visible',
          ),
        }}
      >
        <ul>
          {sortValues
            .filter(key => sort !== key)
            .map(key => (
              <li key={key} onClick={() => handleSortChange(key)}>
                {SORT_LABELS[key]}
              </li>
            ))}
        </ul>
      </StyledPopup>
    </StyledContainer>
  )
}

Sort.propTypes = {
  onSortChange: PropTypes.func.isRequired,
  onDirectionChange: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
}

export default Sort
