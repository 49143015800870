import { graphql } from 'gatsby'
import React, { useMemo, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  StyledPageTitle,
  StyledProductsContainer,
  StyledProductsRow,
  StyledContainer,
  StyledLoadMoreButton,
  StyledButtonContainer,
} from '../components/catalog/styledComponents'
import {
  FilterButton,
  ContentBlock,
  Sort,
  ProductCard,
  Aside,
  Filter,
} from '../components/catalog'
import useFilter from '../components/catalog/filter/useFilter'
import Container from '../components/container'
import FilterModal from '../components/catalog/filter-modal'

export const query = graphql`
  query CatalogPageQuery {
    strapiGraphql {
      catalogPage {
        pageSeo {
          description
          keywords
          title
        }
        pageTitle
        contentTitle
        listTitle
        list {
          id
          title
          text
        }
        image {
          url
          mediaFile {
            publicURL
          }
        }
        filter {
          projectTypes {
            id
            name
          }
          projectFloors {
            id
            name
          }
        }
      }

      products {
        id
        name
        minPrice
        saleMinPrice
        slug
        square
        createdAt
        position
        images {
          url
          mediaFile {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 260, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        projectFloor {
          id
          name
        }
        projectTypes {
          id
          name
        }
      }
    }
  }
`

const LIMIT = 6

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const SORT_VALUES = {
  PRICE: 'minPrice',
  SQUARE: 'square',
  DEFAULT: 'position',
}

export const SORT_LABELS = {
  [SORT_VALUES.PRICE]: 'цене',
  [SORT_VALUES.SQUARE]: 'площади',
  [SORT_VALUES.DEFAULT]: 'умолчанию',
}

const sortFn = (items, sort, direction) => {
  const res = items.slice().sort((a, b) => a[sort] - b[sort])
  if (direction === SORT_DIRECTION.DESC) {
    return res.reverse()
  }
  return res
}

export default function Catalog({ data }) {
  const {
    pageSeo,
    pageTitle,
    filter,
    contentTitle,
    listTitle,
    list,
    image,
  } = data.strapiGraphql.catalogPage
  const { products: allProducts } = data.strapiGraphql

  const priceRange = useMemo(() => {
    const prices = allProducts.map(({ minPrice }) => minPrice)
    return {
      max: Math.max(...prices),
      min: Math.min(...prices),
    }
  }, [allProducts])

  const squareRange = useMemo(() => {
    const squareArr = allProducts.map(({ square }) => square)
    return {
      max: Math.max(...squareArr),
      min: Math.min(...squareArr),
    }
  }, [allProducts])

  const {
    items: filteredProducts,
    setPrice,
    setSquare,
    setTypes,
    setFloors,
  } = useFilter(allProducts, priceRange, squareRange)

  const [sort, setSort] = useState(SORT_VALUES.DEFAULT)
  const [direction, setDirection] = useState(SORT_DIRECTION.DESC)

  const [limit, setLimit] = useState(LIMIT)

  const [products, setProducts] = useState(allProducts)
  const items = sortFn(products, sort, direction).slice(0, limit)

  const [openModalFilter, setOpenModalFilter] = useState(false)
  const handleModalFilterState = () => setOpenModalFilter(v => !v)

  const handleFilterApply = () => setProducts(filteredProducts)
  const handleFilterClear = () => setProducts(allProducts)
  const handleLoadMoreClick = () => setLimit(v => v + LIMIT)

  const productsContainerClassName = 'products-list-container'

  return (
    <Layout instagramOnDesktop>
      <SEO {...pageSeo} />

      <StyledContainer>
        <StyledPageTitle>{pageTitle}</StyledPageTitle>

        <Sort
          onSortChange={setSort}
          onDirectionChange={setDirection}
          sort={sort}
          direction={direction}
        />

        <Aside>
          <Filter
            total={filteredProducts.length}
            types={filter.projectTypes}
            onTypesChange={setTypes}
            onFilterApplyClick={handleFilterApply}
            floors={filter.projectFloors}
            onFloorsChange={setFloors}
            priceRange={priceRange}
            onPriceChange={setPrice}
            squareRange={squareRange}
            onSquareChange={setSquare}
            onFilterClear={handleFilterClear}
          />
        </Aside>

        <StyledProductsContainer className={productsContainerClassName}>
          {!items.length && (
            <div style={{ padding: '16px', textAlign: 'center' }}>
              <h4>Ничего не найдено</h4>
              <p>
                Попробуйте поменять или очистить фильтры, и товары обязательно
                найдутся.
              </p>
            </div>
          )}

          <StyledProductsRow>
            {items.map(
              ({ images, id, slug, minPrice, saleMinPrice, name, square }) => (
                <ProductCard
                  key={id}
                  price={minPrice}
                  salePrice={saleMinPrice}
                  name={name}
                  slug={slug}
                  square={square}
                  image={images[0]}
                />
              ),
            )}
          </StyledProductsRow>

          <StyledButtonContainer>
            <StyledLoadMoreButton
              type='button'
              disabled={products.length <= limit}
              onClick={handleLoadMoreClick}
              outline
            >
              Показать еще
            </StyledLoadMoreButton>
          </StyledButtonContainer>

          <FilterButton
            containerClassName={productsContainerClassName}
            onClick={handleModalFilterState}
          />
        </StyledProductsContainer>
      </StyledContainer>

      <Container>
        <ContentBlock
          title={contentTitle}
          listTitle={listTitle}
          list={list}
          image={image}
        />
      </Container>

      <FilterModal
        open={openModalFilter}
        onClose={handleModalFilterState}
        title='Подбор проекта постройки'
        total={filteredProducts.length}
        onFilterApplyClick={() => {
          handleFilterApply()
          handleModalFilterState()
        }}
        types={filter.projectTypes}
        onTypesChange={setTypes}
        floors={filter.projectFloors}
        onFloorsChange={setFloors}
        priceRange={priceRange}
        onPriceChange={setPrice}
        squareRange={squareRange}
        onSquareChange={setSquare}
        onFilterClear={handleFilterClear}
      />
    </Layout>
  )
}
