import styled from 'styled-components'
import { device, spacing } from '../../../utils/mixins'
import Filter from '../filter'
import { ModalBody } from '../../modal'

export const StyledFilter = styled(Filter)`
  padding: ${spacing(3, 0, 2)};

  @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .fieldset {
    @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
      flex: 0 0 calc(50% - ${spacing(2)});
    }
  }
`

export const StyledModalBody = styled(ModalBody)`
  @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
    width: 600px;
  }
`
